import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Main/Main2.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/About.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/AOS.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Clients.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Companies.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Contact.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Features/Feature5.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Features/FeatureComponent.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/How.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/MakingChange.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/components/Sections/Services.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1230918772/src/chikitsa-website/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
